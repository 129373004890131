<template>
  <div class="modal-card">
    <header class="modal-card-head">
      <p class="modal-card-title">{{ item.name }} - options</p>
      <button
          type="button"
          class="delete"
          @click="$emit('close')"/>
    </header>
    <section class="modal-card-body">
      <div class="modal-item">
        <div class="modal-item infos">
          <h1>Quantité</h1>
          <p>{{ item.amountDescription }}</p>
        </div>
        <div class="modal-item price">
          <b-field v-if="item.fixedAmounts">
            <b-select placeholder="Quantité" v-model="quantity" v-on:input="calculatePrice">
              <option
                  v-for="amount in item.amounts"
                  :value="amount"
                  :key="amount">
                {{ amount }}
              </option>
            </b-select>
          </b-field>
          <b-field v-else>
            <b-numberinput controls-alignment="right" controls-position="compact" :value="quantity" v-model="quantity"
                           v-on:input="calculatePrice"></b-numberinput>
          </b-field>
        </div>
      </div>

      <div class="modal-item" v-for="(option, index) in item.options" :key="index">
        <div class="modal-item infos">
          <h1>{{ option.name }}</h1>
          <p>{{ option.description }}</p>
        </div>
        <div class="modal-item price">
          <p>Prix: <b>{{ option.priceText.replace('{price}', option.price.toFixed(2)) }}</b></p>
          <b-field>
            <b-switch v-model="optionsValue[index]" v-on:input="calculatePrice"></b-switch>
          </b-field>
        </div>
      </div>

      <hr class="solid">

      <div class="modal-item-total">

        <div class="sous-total">
          <div>- Prix:</div>
          <b class="desktop-view">{{ this.quantity }} x {{ this.item.price.toFixed(2) }}€ = {{ (this.quantity * this.item.price).toFixed(2) }}
            €</b>
          <b class="mobile-view">{{ (this.quantity * this.item.price).toFixed(2) }} €</b>
          <div>- Options:</div>
          <b>{{ this.optionsPrice.toFixed(2) }} €</b>
          <div>- Caution:</div>
          <b class="desktop-view">{{ this.quantity }} x {{ this.item.caution.toFixed(2) }}€ = {{
              (this.quantity * this.item.caution).toFixed(2)
            }} €</b>
          <b class="mobile-view">{{ (this.quantity * this.item.caution).toFixed(2) }} €</b>
        </div>

        <div class="total">Total: <b>{{ totalPrice.toFixed(2) }} €</b></div>

      </div>

    </section>
    <footer class="modal-card-foot">
      <b-button icon-left="cart-plus" label="Ajouter à mon panier" @click="addToCart" expanded></b-button>
    </footer>
  </div>
</template>

<script>

export default {
  name: "CartItemOptionsModal",
  props: {
    item: {}
  },
  data() {
    return {
      optionsValue: [],
      quantity: 1,
      optionsPrice: 0,
      totalPrice: this.item.price,
    }
  },
  mounted() {
    for (let i = 0; i < this.item.options.length; i++) {
      this.optionsValue[i] = false
    }
  },
  methods: {
    calculatePrice() {
      this.calculateOptionsPrice()
      this.totalPrice = this.quantity * this.item.price + this.optionsPrice;
    },

    calculateOptionsPrice() {
      let optionsTotal = 0
      for (let i = 0; i < this.item.options.length; i++) {
        if (!this.optionsValue[i]) continue;
        let option = this.item.options[i]
        optionsTotal += option.fixedPrice ? option.price : option.price * this.quantity
      }

      this.optionsPrice = optionsTotal
    },

    addToCart() {
      let item = JSON.parse(JSON.stringify(this.item))

      for (let i = 0; i < this.optionsValue.length; i++) {
        item.options[i]._selected_ = this.optionsValue[i]
      }
      item._amount_ = this.quantity
      item._id_ = '_' + Math.random().toString(36).substring(2, 9);

      this.$store.dispatch("cart/addToCart", item)

      this.$emit('close');

    }
  }
}
</script>

<style scoped>
.modal-item {
  text-align: left;
  display: flex;
  width: 100%;
  margin-top: 10px;
}

.modal-item.infos {
  display: inline;
}

.modal-item.price {
  display: inline;
  text-align: center;
}

.modal-item-total {
  font-size: 24px;
  display: flex;
  text-align: left;
}

.modal-item-total .sous-total {
  width: 100%;
  font-size: 20px;
  margin-left: 20px;
  display: grid;
  grid-template-columns: 0.5fr 1fr;
  grid-template-rows: 1fr;
  grid-gap: 2px;
}

.modal-item-total .sous-total .mobile-view {
  display: none;
}

.modal-item-total .total {
  width: 50%;
  margin-top: 25px;
}

.modal-item.price p {
  font-size: 18px;
}

.modal-item h1 {
  font-size: 28px;
}

.modal-item p {
  font-size: 16px;
}


@media screen and (max-width: 767px) {
  .modal-item-total .sous-total {
    grid-template-columns: 1fr 0.8fr;
  }

  .modal-item-total .sous-total .mobile-view {
    display: initial;
  }


  .modal-item-total .sous-total .desktop-view {
    display: none;
  }
}

</style>