<template>
  <div>

    <div class="w-title" style="margin-bottom: 5px;">

      <nav class="breadcrumb is-small" aria-label="breadcrumbs">
        <ul>
          <li>
            <router-link to="/">Accueil</router-link>
          </li>
          <li>
            <router-link to="/categories">Catégories</router-link>
          </li>
          <li>
            <router-link :to="'/categories/' + category.slug">{{ category.name }}</router-link>
          </li>
          <li class="is-active">
            <router-link :to="'/' + item.slug" aria-current="page">{{ item.name }}</router-link>
          </li>
        </ul>
      </nav>

    </div>

    <div class="columns" style="margin:20px">

      <div class="column is-two-fifths">
        <b-image :alt="item.name" :src="item.image" :responsive="true">

          <template #placeholder>
            <b-skeleton
                class="skeleton-placeholder"
                height="100%"
            ></b-skeleton>
          </template>

        </b-image>
      </div>
      <div class="column" style="text-align: left; margin: 0 20px 20px 20px">
        <h1 class="title">{{ item.name }}</h1>
        <p>{{ item.shortDescription }}</p>
        <br>
        <table class="table is-striped is-bordered" style="font-size: 1.2rem">
          <tbody>
          <tr>
            <td>
              <b>
                <b-icon icon="cash"></b-icon>
                Prix</b>
            </td>
            <td>
              {{item.priceText.replace('{price}', item.price.toFixed(2)) }}
            </td>
          </tr>

          <tr>
            <td>
              <b>
                <b-icon icon="bank-check"></b-icon>
                Caution</b>
            </td>
            <td>
              {{ item.cautionText.replace('{price}', item.caution.toFixed(2)) }}
            </td>
          </tr>

          <tr v-if="item.minAge >= 0">
            <td>
              <b>
                <b-icon icon="drama-masks"></b-icon>
                Age requis</b>
            </td>
            <td>
              {{ item.minAge === 0 ? 'Aucun' : (item.minAge + ' an' + (item.minAge > 1 ? 's' : '')) }}
            </td>
          </tr>

          <tr>
            <td>
              <b>
                <b-icon icon="card-account-details-outline"></b-icon>
                Carte membre
              </b>
            </td>
            <td>
              {{ item.memberCard ? 'Requise' : 'Non requise' }}
            </td>
            <td>
              <b-tooltip label="Notre association a décidé de ne proposer activité ou animation qu’à nos membres qu’ils soient personne physique ou personne morale. La carte de membre est valable 1 an après paiement." multilined dashed>
                ?
              </b-tooltip>
            </td>
          </tr>

          <tr>
            <td>
              <b>
                <b-icon icon="timeline-clock-outline"></b-icon>
                Coefficienté</b>
            </td>
            <td>
              {{ item.type === 'materiel' ? 'Oui' : 'Non' }}
            </td>
            <td>
              <b-tooltip label="Un coefficient est appliqué en fonction du nombre de jours d’utilisation du matériel. Le calcul se fait lors de la génération du devis" multilined dashed>
                ?
              </b-tooltip>
            </td>

          </tr>

          <tr v-if="item.stock === 0">
            <td>
              <span class="tag is-danger is-medium">Rupture de stock</span>
            </td>
          </tr>

          </tbody>
        </table>

        <p>

        </p>
        <b-button type="is-primary is-light" icon-left="cart-plus" size="is-medium" @click="modalActive = true" :disabled="item.stock <= 0">Ajouter
          au panier
        </b-button>
      </div>
    </div>

    <div class="columns" style="margin: 20px">
      <div class="column">

        <div class="tabs is-centered is-boxed">
          <ul>
            <li :class="{'is-active': activeTab === 'description'}">
              <a @click="activeTab = 'description'">
                <span class="icon is-small"><b-icon icon="image-text"></b-icon></span>
                <span>Description</span>
              </a>
            </li>

            <li :class="{'is-active': activeTab === 'options'}">
              <a @click="activeTab = 'options'">
                <span class="icon is-small"><b-icon icon="tune"></b-icon></span>
                <span>Options</span>
              </a>
            </li>

            <li :class="{'is-active': activeTab === 'technique'}">
              <a @click="activeTab = 'technique'">
                <span class="icon is-small"><b-icon icon="cog"></b-icon></span>
                <span>Caractéristiques techniques</span>
              </a>
            </li>
          </ul>
        </div>

        <div v-if="activeTab === 'description'" v-html="compiledDescription" class="container" style="text-align: left;"></div>

        <div style="font-size: 1.2rem; margin-left: 20px" v-if="activeTab === 'options'">
          <div style="margin-top: 5px" v-for="(option, index) in item.options" :key="index">
            <b>{{ option.name }}:</b>
            {{ option.description }} -
            <b>{{
                option.fixedPrice ? option.price.toFixed(2) + ' €' : option.priceText.replace('{price}', option.price.toFixed(2))
              }}</b>
          </div>
        </div>

        <div v-if="activeTab === 'technique'" v-html="compiledTechInfos" class="container" style="text-align: left;"></div>
      </div>
    </div>

    <b-modal
        v-model="modalActive"
        has-modal-card
        trap-focus
        :destroy-on-hide="true"
        aria-role="dialog"
        aria-label="Sélectionner les options"
        aria-modal :can-cancel="['escape', 'x']">
      <template #default="props">
        <cart-item-options-modal :item="item" @close="props.close"></cart-item-options-modal>
      </template>
    </b-modal>


  </div>
</template>

<script>
import {mapGetters} from "vuex";
import CartItemOptionsModal from "@/components/CartItemOptionsModal"
import marked from "marked"

export default {
  name: "Article",
  components: {CartItemOptionsModal},
  data() {
    return {
      item: {},
      category: {},
      modalActive: false,
      activeTab: 'description'
    }
  },

  computed: {
    ...mapGetters("data", ["getCategory", "getItem", "getItemCategory"]),

    compiledTechInfos() {
      return this.replaceHtml(marked(this.item.techInfos))
    },

    compiledDescription() {
      return this.replaceHtml(marked(this.item.description))
    },
  },

  created() {
    this.loadItem()
  },

  methods: {
    // this.$route.params.slug
    loadItem() {
      let item = this.getItem(this.$route.params.slug)

      if (!item) {
        this.$router.push('/categories/' + this.$route.params.slug)
        this.$buefy.notification.open({
          duration: 5000,
          message: `L'article n'a pas été trouvé`,
          position: 'is-top',
          type: 'is-danger',
          hasIcon: true
        })
        return;
      }

      this.item = item
      this.category = this.getItemCategory(item)

      if (!this.category) {
        return
      }

      if (this.$route.params.category !== this.category.slug) {
        this.$router.push('/categories/' + this.category.slug + '/' + item.slug)
      }

    },

    replaceHtml(html) {
      return html.replaceAll('<h1', '<h1 class="is-size-1"')
          .replaceAll('<h2', '<h2 class="is-size-2"')
          .replaceAll('<h3', '<h3 class="is-size-3"')
          .replaceAll('<h4', '<h4 class="is-size-4"')
          .replaceAll('<h5', '<h5 class="is-size-5"')
          .replaceAll('<h6', '<h6 class="is-size-6"')
    }
  },

  beforeRouteUpdate(to, from, next) {
    next()
    this.loadItem()
  },
}
</script>

<style scoped>

.article-content {
  display: flex;
  flex-direction: row;
}

</style>